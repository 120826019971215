@import "./fonts";

@tailwind base;
@tailwind components;

/* Custom css here */
[x-cloak] { display: none !important; }

.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@tailwind utilities;
