@font-face {
  font-family: "Basier Circle Regular";
  src: url("../fonts/BasierCircle-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Basier Circle Bold";
  src: url("../fonts/BasierCircle-Bold.otf") format("opentype");
}

.basier-regular {
  font-family: "Basier Circle Regular", sans-serif;
}

.basier-bold {
  font-family: "Basier Circle Bold", sans-serif;
}
